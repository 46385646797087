












































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import { CookieStorage } from "cookie-storage";
import configProvider from "@common/services/configProvider";
import GlobalErrorSnackBar from "@/components/GlobalErrorSnackBar.vue";

export default Vue.extend({
  components: { GlobalErrorSnackBar },
  data() {
    return {
      valid: false,
      loading: false,
      error: "",
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      logoUrl: configProvider.get("LOGO_URL"),
      aboutUsUrl: configProvider.get("ABOUT_US_URL"),
      userGuideUrl: configProvider.get("USER_GUIDE_URL"),
      data: {},
      showPassword: false,
    };
  },
  methods: {
    async submit() {
      this.error = "";
      this.loading = true;
      try {
        // fix trim all fields
        const { access_token: accessToken } = await coreApiClient.callRaw(
          "auth",
          "login",
          undefined,
          {
            // this.data.loginName, // fix trim all fields
            username: (this.data.loginName || '').trim(),
            password: this.data.password,
            deviceId: userManager.getDeviceId(),
          }
        );
        if (accessToken) {
          userManager.setAccessToken(accessToken);
          new CookieStorage().setItem("access_token", accessToken, {
            expires: new Date(new Date().getTime() + 86400 * 1000),
          });
          coreApiClient.reset();
          if (this.$route.query.redirect) {
            const url = `${location.origin}${location.pathname}#${this.$route.query.redirect}`;
            location.href = url;
          } else {
            location.reload();
          }
        }
      } catch (err) {
        const theErr = err as any;
        if (theErr.response?.body?.message) {
          this.error = theErr.response.body.message;
        } else {
          this.error = err;
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
